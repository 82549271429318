import config from '../config/settings';
import axios from 'axios';
import store from '../helpers/store';
import history from '../helpers/history';
import { alertError, alertSuccess } from '..//redux/actions/alertActions';

async function getUserBody() {
  return await axios
    .get('/users/')
    .then(resp => {
      let users = resp.data;

      let mem = {
        members: users.map(element => {
          return {
            _id: element._id,
            memberNumber: element.memberNumber,
            firstname: element.firstname,
            surname: element.surname,
            privateEmail: element.privateEmail,
            privateTel: element.privateTel,
            job: element.job,
            function: element.function,
            sector: element.sector,
            role: element.role,
            company:
              element.companyValues && element.companyValues.length > 0
                ? element.companyValues[0]
                : undefined,
            circle:
              element.circleValues && element.circleValues.length > 0
                ? element.circleValues[0]
                : undefined,
            hasAvatar: element.hasAvatar,
            avatarUrl: `${config.img}/member/${element._id}`,
          };
        })
      };
      return mem;
    })
    .catch(err => {
      // couldn't load members
      if (![401, 403].includes(err.status)) {
        history.push('/');
      }
      store.dispatch(alertError('Mitglieder konnten nicht geladen werden.'));
    });
}

async function deleteMember(id) {
  return await axios
    .delete('/users/' + id)
    .then(resp => {
      return id;
    })
    .catch(err => {
      history.push('/admin');
      store.dispatch(alertError('Mitglied konnte nicht gelöscht werden.'));
    });
}

async function createMember(data) {
  return await axios
    .post('/users/', data)
    .then(res => {
      return res;
    })
    .catch(err => {
      if (
        err &&
        err.data &&
        err.data.error &&
        err.data.error.type === 'invalid_input'
      ) {
        return Promise.reject(err.data.error.errors);
      }
    });
}

async function changeRole(member) {
  return await axios
    .put('/users/changeRole/' + member._id, member)
    .then(res => {
      store.dispatch(alertSuccess('Rolle wurde geändert.'));
      return res;
    })
    .catch(err => {
      history.push('/admin');
      store.dispatch(alertError('Rolle konnte nicht geändert werden.'));
    });
}

async function updatePassword(member) {
  return await axios
      .put('/users/updatePassword/' + member._id, member)
      .then(res => {
        store.dispatch(alertSuccess('Passwort wurde geändert.'));
        return res;
      })
      .catch(err => {
        history.push('/admin');

        let error = 'Passwort konnte nicht geändert werden.';
        if (err.data.hasOwnProperty('msg')) {
            error = err.data.msg;
        }

        store.dispatch(alertError(error));
      });
}

const memberService = { getUserBody, deleteMember, createMember, changeRole, updatePassword };
export default memberService;
