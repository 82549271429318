import React, { Component } from 'react';
import Login from '../components/Login';
import { connect } from 'react-redux';

import { setNavInvisible } from '../redux/actions/navigationActions';

import '../css/Login.css';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(setNavInvisible());

    const { state } = this.props.location;
    this.state = {
      redirectTo: state && state.from ? state.from : '/',
    }
  }
  render() {
    return <Login redirectTo={this.state.redirectTo} />;
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(LoginPage);
