export const DATA_FETCHING = 'DATA_FETCHING';
export const DATA_FETCHED = 'DATA_FETCHED';
export const DATA_NEWS_FETCHED = 'DATA_NEWS_FETCHED';
export const DATA_NEWS_FETCHING = ' DATA_NEWS_FETCHING';
export const DATA_MEMBER_FETCHED = 'DATA_MEMBER_FETCHED';
export const DATA_MEMBER_FETCHING = 'DATA_MEMBER_FETCHING';
export const DATA_EVENT_FETCHED = 'DATA_EVENT_FETCHED';
export const DATA_EVENT_FETCHING = ' DATA_EVENT_FETCHING';
export const DATA_CIRCLE_FETCHED = 'DATA_CIRCLE_FETCHED';
export const DATA_CIRCLE_FETCHING = ' DATA_CIRCLE_FETCHING';
